/** @jsxImportSource @emotion/react */
import emotionReset from 'emotion-reset';
import { Global, css, keyframes } from '@emotion/react';

export const App = () => {
    return (
        <>
            <Global styles={globalStyle} />

            <p css={paragraphStyle}>nukora.jp</p>
        </>
    );
};

const globalStyle = css`
    ${emotionReset}

    html,
    body {
        width: 100%;
        height: 100%;
    }

    body {
        font-family: 'Fredericka the Great', cursive;

        display: flex;
        justify-content: center;
        align-items: center;
        
        background-image: linear-gradient(150deg, #ffc0fd 37.5%, #ffffff 62.5%);
        background-size: 400% 400%;
        color: #FF3796;

        animation-name: backgroundAnime;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }   

    @keyframes backgroundAnime {
        0% {
            background-position: 100% 100%
        }
        100% {
            background-position: 50% 50%
        }
    }
`;

const paragraphAnime = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
`;

const paragraphStyle = css`
    margin: 10px;
    font-size: 60px;
    width: 0%;

    padding-bottom: 10px;
    overflow-x: hidden;

    animation-name: ${paragraphAnime};
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
`;